:root {
  --sitecolor: #fcc353;
  --textcolor: #022142;
  --titlecolor: #022142;
  --bgcolor-pri: #ffffff;
  --bgcolor-sec: #eee6dc;
  --linkcolor: #022142;
  --linkcolor-hov: #fcc353;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  cursor: pointer;
  padding: 15px 32px 15px 72px;
  appearance: none;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  color: #022142;
  border-radius: 100px;
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  font-family: 'PPNeueMachina', sans-serif;
  font-weight: 800;
  isolation: isolate;
  position: relative;
  border: 1px solid #022142;
  background-color: var(--btn-bgcolor, var(--sitecolor));
}
.button:before {
  content: '';
  left: -1px;
  top: -1px;
  position: absolute;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  border: 1px solid #022142;
  box-sizing: border-box;
  background-color: #fd5b02;
  background-size: 31px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-darkblue.svg);
  transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}
@media (hover: hover) and (pointer: fine) {
  body:not(#edit) .button:hover,
  body:not(#edit) .button:focus {
    padding: 15px 72px 15px 32px;
  }
  body:not(#edit) .button:hover:before,
  body:not(#edit) .button:focus:before {
    content: '';
    left: calc(100% - 47px);
  }
}
body:not(#edit) .button-hover {
  padding: 15px 72px 15px 32px;
}
body:not(#edit) .button-hover:before {
  content: '';
  left: calc(100% - 47px);
}
.button-simple {
  cursor: pointer;
  padding: 13px 32px 13px 32px;
  appearance: none;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  color: #022142;
  border-radius: 100px;
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  font-family: 'PPNeueMachina', sans-serif;
  font-weight: 800;
  isolation: isolate;
  position: relative;
  background-color: #fd5b02;
  border: 1px solid #022142;
}
.button-sec {
  cursor: pointer;
  padding: 5px 0 3px 32px;
  appearance: none;
  box-sizing: border-box;
  text-decoration: none;
  transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  color: var(--textcolor);
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  font-family: 'PPNeueMachina', sans-serif;
  font-weight: 800;
  isolation: isolate;
  position: relative;
  min-height: 24px;
}
.button-sec:before {
  content: '';
  left: 0;
  top: calc(50% - 12px);
  position: absolute;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  mask-size: 24px;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right-darkblue.svg);
  transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  background-color: var(--textcolor);
}
@media (hover: hover) and (pointer: fine) {
  body:not(#edit) .button-sec:hover,
  body:not(#edit) .button-sec:focus {
    padding: 5px 32px 3px 0;
  }
  body:not(#edit) .button-sec:hover:before,
  body:not(#edit) .button-sec:focus:before {
    content: '';
    left: calc(100% - 24px);
  }
}
body:not(#edit) .button-sec-hover {
  padding: 5px 32px 3px 0;
}
body:not(#edit) .button-sec-hover:before {
  content: '';
  left: calc(100% - 24px);
}
.container--intro {
  grid-template-columns: repeat(12, 1fr);
  gap: 40px;
  padding-bottom: calc(var(--spaceTotal) * 2 + 10px);
  margin-top: 0;
  margin-bottom: 0;
  min-height: calc(100vh - 75px);
}
.container--intro .content {
  grid-column: span 7;
  padding-right: 15.06849315%;
  box-sizing: border-box;
}
@media (max-width: 1200px) {
  .container--intro .content {
    grid-column: span 6;
  }
}
.container--intro .slider {
  grid-column: span 5;
}
@media (max-width: 1200px) {
  .container--intro .slider {
    grid-column: span 6;
  }
}
.container--folding {
  grid-template-columns: repeat(12, 1fr);
  gap: 40px;
}
.container--folding .area {
  grid-column: span 8;
}
.container--folding .part--foldingTitle {
  grid-column: span 4;
  margin-top: calc(var(--spaceTotal) * 1);
}
.container--getintouch {
  grid-template-columns: repeat(12, 1fr);
  gap: 40px;
}
.container--getintouch .content {
  grid-column: span 6;
}
.container--getintouch .form {
  grid-column: span 6;
}
.footarea {
  grid-template-columns: repeat(12, 1fr);
  gap: 40px;
}
.footpart {
  grid-column: span 12;
}
.footpart--slim {
  grid-column: span 3;
}
.footpart--half {
  grid-column: span 6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.area--one {
  grid-template-columns: repeat(12, 1fr);
}
.area--one .unitOne--1-1-indent {
  padding: 0 17.1875%;
  box-sizing: border-box;
}
.area--one .unitOne--1-1-indent .unit__body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 40px;
}
.area--one .unitOne--1-1-indent .part {
  grid-column: span 2;
}
.area--one .unitOne--1-1-indent .part--slim {
  grid-column: span 1;
}
.unitOne--1-1-indentRight {
  padding-right: 34.375%;
  box-sizing: border-box;
}
.area--one .unit {
  grid-column: span 12;
}
.area--one .unitOne--1-2 {
  grid-column: span 6;
}
.area--one .unitOne--1-3 {
  grid-column: span 4;
}
.area--one .unitOne--2-3 {
  grid-column: span 8;
}
.area--one .unitOne--1-4 {
  grid-column: span 3;
}
.area--one .unitThree {
  grid-column: span 4;
  margin-top: 0;
  margin-bottom: 0;
}
.area--one .unitThree:nth-child(-n+3 of .unitThree) {
  margin-top: calc(var(--spaceUnit) * 1);
}
.area--one .unitThree:nth-child(3n+2 of .unitThree) {
  margin-top: 127px;
}
.area--one .unitFive {
  grid-column: span 4;
  /* Dealing with 2 orphan items */
  /* Dealing with single orphan */
}
.area--one .unitFive .unit__content {
  aspect-ratio: 1 / 1;
}
.area--one .unitFive:nth-last-child(1 of .unitFive.unit--hasBackground):nth-child(3n - 1 of .unitFive.unit--hasBackground),
.area--one .unitFive:nth-last-child(1 of .unitFive:not(.unit--hasBackground)):nth-child(3n - 1 of .unitFive:not(.unit--hasBackground)) {
  grid-column-end: -3;
}
.area--one .unitFive:nth-last-child(2 of .unitFive.unit--hasBackground):nth-child(3n + 1 of .unitFive.unit--hasBackground),
.area--one .unitFive:nth-last-child(2 of .unitFive:not(.unit--hasBackground)):nth-child(3n + 1 of .unitFive:not(.unit--hasBackground)) {
  grid-column-end: 7;
}
.area--one .unitFive:nth-last-child(1 of .unitFive.unit--hasBackground):nth-child(3n - 2 of .unitFive.unit--hasBackground),
.area--one .unitFive:nth-last-child(1 of .unitFive:not(.unit--hasBackground)):nth-child(3n - 2 of .unitFive:not(.unit--hasBackground)) {
  grid-column-end: 9;
}
.area--one .unitSix {
  display: grid;
  gap: 40px;
  justify-content: end;
  grid-template-columns: repeat(12, 1fr);
}
.area--one .unitSix .unit__background {
  grid-column: 1 / 5;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  padding-top: calc(var(--spacePart) * 1);
  box-sizing: border-box;
}
.area--one .unitSix .unit__content {
  width: auto;
  grid-column: 5 / 13;
}
/*# sourceMappingURL=./screen-large.css.map */